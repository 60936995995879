import { useState } from 'react';
import { useAppSelector } from './useAppSelector';
import { accountPositionsSelector } from '~/state/selectors';
import { useSubscribeQuote } from './useSubscribeQuote';

export const useOpenTradesData = () => {
    const positions = useAppSelector(accountPositionsSelector);
    const [openSymbol, setOpenSymbol] = useState<string | null>(null);
    useSubscribeQuote(openSymbol);

    return {
        positions,
        openSymbol,
        setOpenSymbol,
    };
};
