import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Pressable, Platform } from 'react-native';
import { scaleSize, isDesktop } from '~/constants/scale';
import Header from '../More/Header';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import { Theme } from '~/theme';
import { AuthDataService } from '~/api-services';
import { TraderAccountsReturnData } from '~/api-services/AuthDataService/types';
import { useStyles} from '~/hooks';
import TraderAccountRow from './TraderAccountRow';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import ConfirmationMessage from '../ConfirmationMessage';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import LocalStorage from "~/state/localStorage";
import { setupAuthHeaders } from "~/api-services/baseClient";

type Props = {
    setActiveRightTab?: Function;
};
export default function Accounts({ setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    const [traderAccounts, setTraderAccounts] = useState<TraderAccountsReturnData>(null);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [confirmationVisible, setConfirmationVisible] = useState(false);

    useEffect(() => {
        (async () => {
            const { status, data } = await AuthDataService.getTraderAccounts()
            if (status === 200 && data.status) {
                setTraderAccounts(data.returnData);
            }
        })();
    }, []);

    async function switchAccount(accountId: number) {
        try {
            const { status, data } = await AuthDataService.switchAccount(accountId);
            if (status === 200 && data.returnData) {
                const oldUserAuthData =  await LocalStorage.userAuthData.get();
                if (oldUserAuthData && oldUserAuthData.token && !data.returnData.token) {
                    // keep the old token
                    data.returnData.token = oldUserAuthData.token;
                }
                await LocalStorage.userAuthData.set(data.returnData);
                setupAuthHeaders(data.returnData.sessionId, data.returnData.language);

                if (Platform.OS === 'web') {
                    window.location.reload();
                }
                else {
                    linkTo(paths.home);
                }

                return data.returnData;
            } else {
                setConfirmationProps({ text: data.errorCode ? data.errorCode : 'Error', error: true });
                setConfirmationVisible(true);
            }
        }
        catch (e) {
            setConfirmationProps({ text: e instanceof Error ? e.message : 'Error', error: true });
            setConfirmationVisible(true);
        }
    }

    return (
        <>
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader goToScreenName="more" title="accounts-label" setActiveRightTab={setActiveRightTab} />
            
            <View style={styles.container}>

                <View style={styles.viewContainer}>
                <ScrollbarWrapper>
                    {traderAccounts && traderAccounts.traderAccounts && traderAccounts.traderAccounts.length > 0 ?

                        traderAccounts.traderAccounts.map((traderAccount, key) => (
                            <Pressable
                                onPress={() => {traderAccount.enabled ? switchAccount(traderAccount.accountId) : null}}
                                key={key}
                            >
                                <TraderAccountRow traderAccount={traderAccount} selectedAccountId={traderAccounts.currentAccountId} />
                            </Pressable>
                        ))


                        :
                        null}
                    <ConfirmationMessage
                        text={confirmationProps.text}
                        error={confirmationProps.error}
                        visible={confirmationVisible}
                        setVisible={setConfirmationVisible}
                    />
                    </ScrollbarWrapper>
                </View>
            </View>
            
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
        viewContainer: { height: '100%', justifyContent: 'center', paddingBottom: scaleSize(7) },
    });
