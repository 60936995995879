import { LinkingOptions } from '@react-navigation/native';
import paths from './paths';
import { generateWatchlistItemId } from './utilts';

type Linking = LinkingOptions<ReactNavigation.RootParamList>;

const linking: Linking = {
    prefixes: ['localhost'], // TODO: deep linking
    config: {
        screens: {
            Loading: paths.loading,
            Login: paths.login,
            LoginIB: paths.loginIB,
            AutoLogin: paths.autoLogin,
            Registration: paths.registration,
            RegistrationInvesting: paths.registrationInvesting,
            ForgotPassword: paths.forgotPassword,
            ResetPassword: paths.resetPassword,
            Confirmation: paths.confirmation,
            Chart: paths.chartingLibrary,
            Desktop: paths.desktop,
            HomeTabs: {
                screens: {
                    DealsStack: {
                        screens: {
                            EditOpenPosition: paths.editOpenPosition,
                            EditPendingOrder: paths.editPendingOrder,
                            DealsTopTabs: {
                                screens: {
                                    OpenTrades: paths.openTrades,
                                    AllOrders: paths.allOrders,
                                    Result: paths.result,
                                    Cash: paths.cash,
                                    Commissions: paths.commissions,
                                    InterestSwap: paths.interestSwap,
                                },
                            },
                        },
                    },
                    MarketsStack: {
                        screens: {
                            EditFavoritesList: paths.editFavoritesList,
                            BuySellPosition: paths.buySellPosition,
                            MarketOrder: paths.marketOrder,
                            Investments: paths.investments,
                            MarketTopTabs: {
                                screens: {},
                            },
                        },
                    },
                    NewsStack: {
                        screens: {
                            News: paths.news,
                            Notifications: paths.notifications,
                        },
                    },
                    MoreStack: {
                        screens: {
                            More: paths.more,
                            Settings: paths.settings,
                            Accounts: paths.accounts,
                            ChangePassword: paths.changePassword,
                            NotificationsSettings: paths.notificationsSettings,
                            WithdrawFunds: paths.withdrawFunds,
                            AddFunds: paths.addFunds,
                            AddFundsCategory: paths.addFundsCategory,
                            AddFundsCard: paths.addFundsCard,
                            AddFundsAlternativePayment: paths.addFundsAlternativePayment,
                            AddFundsAlternative: paths.addFundsAlternative,
                            AddFundsBank: paths.addFundsBank,
                            UploadDocuments: paths.uploadDocuments,
                            VerificationCenter: paths.verificationCenter,
                            ClientReport: paths.clientReport,
                            ResultReport: paths.resultReport,
                            SwapReport: paths.swapReport,
                        },
                    },
                },
            },
        },
    },
};

export const updateLinking = (cb: (l: Linking) => void) => cb(linking);

export const setWatchlistLinking = (ids: number[]) => {
    const screens: { [key: string]: string } = (linking as any).config.screens.HomeTabs.screens.MarketsStack.screens
        .MarketTopTabs.screens;
    for (const id of ids) {
        screens[generateWatchlistItemId(id)] = paths.watchlistItem(id);
    }
};

export const getLinking = () => linking;
