import { countries } from '~/components/features/Authentication/RegistrationForm/countries';
import axios from '../baseClient';

import { ResolveCountryResponse } from './types';

class ResolveCountryService {
    private static domain = '/api/24yieldservice';

    resolveCountry() {
        return axios.post<ResolveCountryResponse>(`${ResolveCountryService.domain}/resolve`);
    }

    getCountriesList(): countries[] {
        let countriesList: countries[] = [];

        countriesList.push(
            {
                countryId: 0,
                name: 'Please Select Country',
                abbreviation: '',
                phonePrefix: '',
                riskGroupID: 0,
            },
            {
                countryId: 1,
                name: 'Afghanistan',
                abbreviation: 'AF',
                phonePrefix: '93',
                riskGroupID: 3,
            },
            {
                countryId: 2,
                name: 'Albania',
                abbreviation: 'AL',
                phonePrefix: '355',
                riskGroupID: 2,
            },
            {
                countryId: 3,
                name: 'Algeria',
                abbreviation: 'DZ',
                phonePrefix: '213',
                riskGroupID: 3,
            },
            {
                countryId: 4,
                name: 'American Samoa',
                abbreviation: 'AS',
                phonePrefix: '1',
                riskGroupID: 1,
            },
            {
                countryId: 5,
                name: 'Andorra',
                abbreviation: 'AD',
                phonePrefix: '376',
                riskGroupID: 0,
            },
            {
                countryId: 6,
                name: 'Angola',
                abbreviation: 'AO',
                phonePrefix: '244',
                riskGroupID: 0,
            },
            {
                countryId: 7,
                name: 'Anguilla',
                abbreviation: 'AI',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 8,
                name: 'Antigua and Barbuda',
                abbreviation: 'AG',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 9,
                name: 'Argentina',
                abbreviation: 'AR',
                phonePrefix: '54',
                riskGroupID: 0,
            },
            {
                countryId: 10,
                name: 'Armenia',
                abbreviation: 'AM',
                phonePrefix: '374',
                riskGroupID: 0,
            },
            {
                countryId: 11,
                name: 'Aruba',
                abbreviation: 'AW',
                phonePrefix: '297',
                riskGroupID: 0,
            },
            {
                countryId: 12,
                name: 'Australia',
                abbreviation: 'AU',
                phonePrefix: '61',
                riskGroupID: 0,
            },
            {
                countryId: 13,
                name: 'Austria',
                abbreviation: 'AT',
                phonePrefix: '43',
                riskGroupID: 0,
            },
            {
                countryId: 14,
                name: 'Azerbaijan',
                abbreviation: 'AZ',
                phonePrefix: '994',
                riskGroupID: 0,
            },
            {
                countryId: 15,
                name: 'Bahrain',
                abbreviation: 'BH',
                phonePrefix: '973',
                riskGroupID: 0,
            },
            {
                countryId: 16,
                name: 'Bangladesh',
                abbreviation: 'BD',
                phonePrefix: '880',
                riskGroupID: 0,
            },
            {
                countryId: 17,
                name: 'Barbados',
                abbreviation: 'BB',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 18,
                name: 'Belarus',
                abbreviation: 'BY',
                phonePrefix: '375',
                riskGroupID: 3,
            },
            {
                countryId: 19,
                name: 'Belgium',
                abbreviation: 'BE',
                phonePrefix: '32',
                riskGroupID: 0,
            },
            {
                countryId: 20,
                name: 'Belize',
                abbreviation: 'BZ',
                phonePrefix: '501',
                riskGroupID: 0,
            },
            {
                countryId: 21,
                name: 'Benin',
                abbreviation: 'BJ',
                phonePrefix: '229',
                riskGroupID: 0,
            },
            {
                countryId: 22,
                name: 'Bermuda',
                abbreviation: 'BM',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 23,
                name: 'Bhutan',
                abbreviation: 'BT',
                phonePrefix: '975',
                riskGroupID: 0,
            },
            {
                countryId: 24,
                name: 'Bolivia',
                abbreviation: 'BO',
                phonePrefix: '591',
                riskGroupID: 0,
            },
            {
                countryId: 25,
                name: 'Bosnia and Herzegovina',
                abbreviation: 'BA',
                phonePrefix: '387',
                riskGroupID: 3,
            },
            {
                countryId: 26,
                name: 'Botswana',
                abbreviation: 'BW',
                phonePrefix: '267',
                riskGroupID: 0,
            },
            {
                countryId: 27,
                name: 'Bouvet Island',
                abbreviation: 'BV',
                phonePrefix: '47',
                riskGroupID: 0,
            },
            {
                countryId: 28,
                name: 'Brazil',
                abbreviation: 'BR',
                phonePrefix: '55',
                riskGroupID: 0,
            },
            {
                countryId: 29,
                name: 'Chagos Islands',
                abbreviation: 'IO',
                phonePrefix: '246',
                riskGroupID: 0,
            },
            {
                countryId: 30,
                name: 'Virgin Islands',
                abbreviation: 'VG',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 31,
                name: 'Brunei',
                abbreviation: 'BN',
                phonePrefix: '673',
                riskGroupID: 0,
            },
            {
                countryId: 32,
                name: 'Bulgaria',
                abbreviation: 'BG',
                phonePrefix: '359',
                riskGroupID: 0,
            },
            {
                countryId: 33,
                name: 'Burkina Faso',
                abbreviation: 'BF',
                phonePrefix: '226',
                riskGroupID: 0,
            },
            {
                countryId: 34,
                name: 'Burundi',
                abbreviation: 'BI',
                phonePrefix: '257',
                riskGroupID: 0,
            },
            {
                countryId: 35,
                name: "Cote d'Ivoire",
                abbreviation: 'CI',
                phonePrefix: '225',
                riskGroupID: 0,
            },
            {
                countryId: 36,
                name: 'Cambodia',
                abbreviation: 'KH',
                phonePrefix: '855',
                riskGroupID: 0,
            },
            {
                countryId: 37,
                name: 'Cameroon',
                abbreviation: 'CM',
                phonePrefix: '237',
                riskGroupID: 0,
            },
            {
                countryId: 38,
                name: 'Canada',
                abbreviation: 'CA',
                phonePrefix: '1',
                riskGroupID: 1,
            },
            {
                countryId: 39,
                name: 'Cape Verde',
                abbreviation: 'CV',
                phonePrefix: '238',
                riskGroupID: 0,
            },
            {
                countryId: 40,
                name: 'Cayman Islands',
                abbreviation: 'KY',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 41,
                name: 'CAR',
                abbreviation: 'CF',
                phonePrefix: '236',
                riskGroupID: 0,
            },
            {
                countryId: 42,
                name: 'Chad',
                abbreviation: 'TD',
                phonePrefix: '235',
                riskGroupID: 0,
            },
            {
                countryId: 43,
                name: 'Chile',
                abbreviation: 'CL',
                phonePrefix: '56',
                riskGroupID: 0,
            },
            {
                countryId: 44,
                name: 'China',
                abbreviation: 'CN',
                phonePrefix: '86',
                riskGroupID: 0,
            },
            {
                countryId: 45,
                name: 'Christmas Island',
                abbreviation: 'CX',
                phonePrefix: '61',
                riskGroupID: 0,
            },
            {
                countryId: 46,
                name: 'Cocos Islands',
                abbreviation: 'CC',
                phonePrefix: '891',
                riskGroupID: 0,
            },
            {
                countryId: 47,
                name: 'Colombia',
                abbreviation: 'CO',
                phonePrefix: '57',
                riskGroupID: 0,
            },
            {
                countryId: 48,
                name: 'Comoros',
                abbreviation: 'KM',
                phonePrefix: '269',
                riskGroupID: 0,
            },
            {
                countryId: 49,
                name: 'Congo',
                abbreviation: 'CG',
                phonePrefix: '242',
                riskGroupID: 0,
            },
            {
                countryId: 50,
                name: 'Cook Islands',
                abbreviation: 'CK',
                phonePrefix: '682',
                riskGroupID: 0,
            },
            {
                countryId: 51,
                name: 'Costa Rica',
                abbreviation: 'CR',
                phonePrefix: '506',
                riskGroupID: 0,
            },
            {
                countryId: 52,
                name: 'Croatia',
                abbreviation: 'HR',
                phonePrefix: '385',
                riskGroupID: 0,
            },
            {
                countryId: 53,
                name: 'Cuba',
                abbreviation: 'CU',
                phonePrefix: '53',
                riskGroupID: 1,
            },
            {
                countryId: 54,
                name: 'Cyprus',
                abbreviation: 'CY',
                phonePrefix: '357',
                riskGroupID: 4,
            },
            {
                countryId: 55,
                name: 'Czech Republic',
                abbreviation: 'CZ',
                phonePrefix: '420',
                riskGroupID: 0,
            },
            {
                countryId: 56,
                name: 'Congo Republic',
                abbreviation: 'CD',
                phonePrefix: '243',
                riskGroupID: 0,
            },
            {
                countryId: 57,
                name: 'Denmark',
                abbreviation: 'DK',
                phonePrefix: '45',
                riskGroupID: 0,
            },
            {
                countryId: 58,
                name: 'Djibouti',
                abbreviation: 'DJ',
                phonePrefix: '253',
                riskGroupID: 0,
            },
            {
                countryId: 59,
                name: 'Dominica',
                abbreviation: 'DM',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 60,
                name: 'Dominican Republic',
                abbreviation: 'DO',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 61,
                name: 'East Timor',
                abbreviation: 'TL',
                phonePrefix: '670',
                riskGroupID: 0,
            },
            {
                countryId: 62,
                name: 'Ecuador',
                abbreviation: 'EC',
                phonePrefix: '593',
                riskGroupID: 0,
            },
            {
                countryId: 63,
                name: 'Egypt',
                abbreviation: 'EG',
                phonePrefix: '20',
                riskGroupID: 0,
            },
            {
                countryId: 64,
                name: 'El Salvador',
                abbreviation: 'SV',
                phonePrefix: '503',
                riskGroupID: 0,
            },
            {
                countryId: 65,
                name: 'Equatorial Guinea',
                abbreviation: 'GQ',
                phonePrefix: '240',
                riskGroupID: 0,
            },
            {
                countryId: 66,
                name: 'Eritrea',
                abbreviation: 'ER',
                phonePrefix: '291',
                riskGroupID: 0,
            },
            {
                countryId: 67,
                name: 'Estonia',
                abbreviation: 'EE',
                phonePrefix: '372',
                riskGroupID: 0,
            },
            {
                countryId: 68,
                name: 'Ethiopia',
                abbreviation: 'ET',
                phonePrefix: '251',
                riskGroupID: 3,
            },
            {
                countryId: 69,
                name: 'Faeroe Islands',
                abbreviation: 'FO',
                phonePrefix: '298',
                riskGroupID: 0,
            },
            {
                countryId: 70,
                name: 'Falkland Islands',
                abbreviation: 'FK',
                phonePrefix: '500',
                riskGroupID: 0,
            },
            {
                countryId: 71,
                name: 'Fiji',
                abbreviation: 'FJ',
                phonePrefix: '679',
                riskGroupID: 0,
            },
            {
                countryId: 72,
                name: 'Finland',
                abbreviation: 'FI',
                phonePrefix: '358',
                riskGroupID: 0,
            },
            {
                countryId: 73,
                name: 'Macedonia',
                abbreviation: 'MK',
                phonePrefix: '389',
                riskGroupID: 0,
            },
            {
                countryId: 74,
                name: 'France',
                abbreviation: 'FR',
                phonePrefix: '33',
                riskGroupID: 0,
            },
            {
                countryId: 75,
                name: 'French Polynesia',
                abbreviation: 'PF',
                phonePrefix: '689',
                riskGroupID: 0,
            },
            {
                countryId: 76,
                name: 'French Southern',
                abbreviation: 'TF',
                phonePrefix: '594',
                riskGroupID: 0,
            },
            {
                countryId: 77,
                name: 'Gabon',
                abbreviation: 'GA',
                phonePrefix: '241',
                riskGroupID: 0,
            },
            {
                countryId: 78,
                name: 'Georgia',
                abbreviation: 'GE',
                phonePrefix: '995',
                riskGroupID: 0,
            },
            {
                countryId: 79,
                name: 'Germany',
                abbreviation: 'DE',
                phonePrefix: '49',
                riskGroupID: 0,
            },
            {
                countryId: 80,
                name: 'Ghana',
                abbreviation: 'GH',
                phonePrefix: '233',
                riskGroupID: 0,
            },
            {
                countryId: 81,
                name: 'Gibraltar',
                abbreviation: 'GI',
                phonePrefix: '350',
                riskGroupID: 0,
            },
            {
                countryId: 82,
                name: 'Greece',
                abbreviation: 'GR',
                phonePrefix: '30',
                riskGroupID: 0,
            },
            {
                countryId: 83,
                name: 'Greenland',
                abbreviation: 'GL',
                phonePrefix: '299',
                riskGroupID: 0,
            },
            {
                countryId: 84,
                name: 'Grenada',
                abbreviation: 'GD',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 85,
                name: 'Guadeloupe',
                abbreviation: 'GP',
                phonePrefix: '590',
                riskGroupID: 0,
            },
            {
                countryId: 86,
                name: 'Guam',
                abbreviation: 'GU',
                phonePrefix: '1',
                riskGroupID: 1,
            },
            {
                countryId: 87,
                name: 'Guatemala',
                abbreviation: 'GT',
                phonePrefix: '502',
                riskGroupID: 0,
            },
            {
                countryId: 88,
                name: 'Guinea',
                abbreviation: 'GN',
                phonePrefix: '224',
                riskGroupID: 0,
            },
            {
                countryId: 89,
                name: 'Guinea-Bissau',
                abbreviation: 'GW',
                phonePrefix: '245',
                riskGroupID: 0,
            },
            {
                countryId: 90,
                name: 'Guyana',
                abbreviation: 'GY',
                phonePrefix: '592',
                riskGroupID: 3,
            },
            {
                countryId: 91,
                name: 'Haiti',
                abbreviation: 'HT',
                phonePrefix: '509',
                riskGroupID: 0,
            },
            {
                countryId: 92,
                name: 'Honduras',
                abbreviation: 'HN',
                phonePrefix: '504',
                riskGroupID: 0,
            },
            {
                countryId: 93,
                name: 'Hong Kong',
                abbreviation: 'HK',
                phonePrefix: '852',
                riskGroupID: 0,
            },
            {
                countryId: 94,
                name: 'Hungary',
                abbreviation: 'HU',
                phonePrefix: '36',
                riskGroupID: 0,
            },
            {
                countryId: 95,
                name: 'Iceland',
                abbreviation: 'IS',
                phonePrefix: '354',
                riskGroupID: 0,
            },
            {
                countryId: 96,
                name: 'India',
                abbreviation: 'IN',
                phonePrefix: '91',
                riskGroupID: 0,
            },
            {
                countryId: 97,
                name: 'Indonesia',
                abbreviation: 'ID',
                phonePrefix: '62',
                riskGroupID: 0,
            },
            {
                countryId: 98,
                name: 'Iran',
                abbreviation: 'IR',
                phonePrefix: '98',
                riskGroupID: 1,
            },
            {
                countryId: 99,
                name: 'Iraq',
                abbreviation: 'IQ',
                phonePrefix: '964',
                riskGroupID: 3,
            },
            {
                countryId: 100,
                name: 'Ireland',
                abbreviation: 'IE',
                phonePrefix: '353',
                riskGroupID: 0,
            },
            {
                countryId: 101,
                name: 'Israel',
                abbreviation: 'IL',
                phonePrefix: '972',
                riskGroupID: 3,
            },
            {
                countryId: 102,
                name: 'Italy',
                abbreviation: 'IT',
                phonePrefix: '39',
                riskGroupID: 0,
            },
            {
                countryId: 103,
                name: 'Jamaica',
                abbreviation: 'JM',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 104,
                name: 'Japan',
                abbreviation: 'JP',
                phonePrefix: '81',
                riskGroupID: 2,
            },
            {
                countryId: 105,
                name: 'Jordan',
                abbreviation: 'JO',
                phonePrefix: '962',
                riskGroupID: 0,
            },
            {
                countryId: 106,
                name: 'Kazakhstan',
                abbreviation: 'KZ',
                phonePrefix: '7',
                riskGroupID: 0,
            },
            {
                countryId: 107,
                name: 'Kenya',
                abbreviation: 'KE',
                phonePrefix: '254',
                riskGroupID: 0,
            },
            {
                countryId: 108,
                name: 'Kiribati',
                abbreviation: 'KI',
                phonePrefix: '686',
                riskGroupID: 0,
            },
            {
                countryId: 109,
                name: 'Kuwait',
                abbreviation: 'KW',
                phonePrefix: '965',
                riskGroupID: 0,
            },
            {
                countryId: 110,
                name: 'Kyrgyzstan',
                abbreviation: 'KG',
                phonePrefix: '996',
                riskGroupID: 0,
            },
            {
                countryId: 111,
                name: 'Laos',
                abbreviation: 'LA',
                phonePrefix: '856',
                riskGroupID: 3,
            },
            {
                countryId: 112,
                name: 'Latvia',
                abbreviation: 'LV',
                phonePrefix: '371',
                riskGroupID: 0,
            },
            {
                countryId: 113,
                name: 'Lebanon',
                abbreviation: 'LB',
                phonePrefix: '961',
                riskGroupID: 3,
            },
            {
                countryId: 114,
                name: 'Lesotho',
                abbreviation: 'LS',
                phonePrefix: '266',
                riskGroupID: 0,
            },
            {
                countryId: 115,
                name: 'Liberia',
                abbreviation: 'LR',
                phonePrefix: '231',
                riskGroupID: 0,
            },
            {
                countryId: 116,
                name: 'Libya',
                abbreviation: 'LY',
                phonePrefix: '218',
                riskGroupID: 0,
            },
            {
                countryId: 117,
                name: 'Liechtenstein',
                abbreviation: 'LI',
                phonePrefix: '423',
                riskGroupID: 0,
            },
            {
                countryId: 118,
                name: 'Lithuania',
                abbreviation: 'LT',
                phonePrefix: '370',
                riskGroupID: 0,
            },
            {
                countryId: 119,
                name: 'Luxembourg',
                abbreviation: 'LU',
                phonePrefix: '352',
                riskGroupID: 0,
            },
            {
                countryId: 120,
                name: 'Macau',
                abbreviation: 'MO',
                phonePrefix: '853',
                riskGroupID: 0,
            },
            {
                countryId: 121,
                name: 'Madagascar',
                abbreviation: 'MG',
                phonePrefix: '261',
                riskGroupID: 0,
            },
            {
                countryId: 122,
                name: 'Malawi',
                abbreviation: 'MW',
                phonePrefix: '265',
                riskGroupID: 0,
            },
            {
                countryId: 123,
                name: 'Malaysia',
                abbreviation: 'MY',
                phonePrefix: '60',
                riskGroupID: 0,
            },
            {
                countryId: 124,
                name: 'Maldives',
                abbreviation: 'MV',
                phonePrefix: '960',
                riskGroupID: 0,
            },
            {
                countryId: 125,
                name: 'Mali',
                abbreviation: 'ML',
                phonePrefix: '223',
                riskGroupID: 0,
            },
            {
                countryId: 126,
                name: 'Malta',
                abbreviation: 'MT',
                phonePrefix: '356',
                riskGroupID: 0,
            },
            {
                countryId: 127,
                name: 'Marshall Islands',
                abbreviation: 'MH',
                phonePrefix: '692',
                riskGroupID: 0,
            },
            {
                countryId: 128,
                name: 'Martinique',
                abbreviation: 'MQ',
                phonePrefix: '596',
                riskGroupID: 0,
            },
            {
                countryId: 129,
                name: 'Mauritania',
                abbreviation: 'MR',
                phonePrefix: '222',
                riskGroupID: 0,
            },
            {
                countryId: 130,
                name: 'Mauritius',
                abbreviation: 'MU',
                phonePrefix: '230',
                riskGroupID: 0,
            },
            {
                countryId: 131,
                name: 'Mayotte',
                abbreviation: 'YT',
                phonePrefix: '269',
                riskGroupID: 0,
            },
            {
                countryId: 132,
                name: 'Mexico',
                abbreviation: 'MX',
                phonePrefix: '52',
                riskGroupID: 0,
            },
            {
                countryId: 133,
                name: 'Micronesia',
                abbreviation: 'FM',
                phonePrefix: '691',
                riskGroupID: 0,
            },
            {
                countryId: 134,
                name: 'Moldova',
                abbreviation: 'MD',
                phonePrefix: '373',
                riskGroupID: 0,
            },
            {
                countryId: 135,
                name: 'Monaco',
                abbreviation: 'MC',
                phonePrefix: '377',
                riskGroupID: 0,
            },
            {
                countryId: 136,
                name: 'Mongolia',
                abbreviation: 'MN',
                phonePrefix: '976',
                riskGroupID: 0,
            },
            {
                countryId: 137,
                name: 'Montserrat',
                abbreviation: 'MS',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 138,
                name: 'Morocco',
                abbreviation: 'MA',
                phonePrefix: '212',
                riskGroupID: 0,
            },
            {
                countryId: 139,
                name: 'Mozambique',
                abbreviation: 'MZ',
                phonePrefix: '258',
                riskGroupID: 0,
            },
            {
                countryId: 140,
                name: 'Myanmar',
                abbreviation: 'MM',
                phonePrefix: '95',
                riskGroupID: 0,
            },
            {
                countryId: 141,
                name: 'Namibia',
                abbreviation: 'NA',
                phonePrefix: '264',
                riskGroupID: 1,
            },
            {
                countryId: 142,
                name: 'Nauru',
                abbreviation: 'NR',
                phonePrefix: '674',
                riskGroupID: 0,
            },
            {
                countryId: 143,
                name: 'Netherlands',
                abbreviation: 'NL',
                phonePrefix: '31',
                riskGroupID: 0,
            },
            {
                countryId: 144,
                name: 'Netherlands Antilles',
                abbreviation: 'AN',
                phonePrefix: '599',
                riskGroupID: 0,
            },
            {
                countryId: 145,
                name: 'New Caledonia',
                abbreviation: 'NC',
                phonePrefix: '687',
                riskGroupID: 0,
            },
            {
                countryId: 146,
                name: 'New Zealand',
                abbreviation: 'NZ',
                phonePrefix: '64',
                riskGroupID: 2,
            },
            {
                countryId: 147,
                name: 'Nicaragua',
                abbreviation: 'NI',
                phonePrefix: '505',
                riskGroupID: 0,
            },
            {
                countryId: 148,
                name: 'Niger',
                abbreviation: 'NE',
                phonePrefix: '227',
                riskGroupID: 0,
            },
            {
                countryId: 149,
                name: 'Nigeria',
                abbreviation: 'NG',
                phonePrefix: '234',
                riskGroupID: 0,
            },
            {
                countryId: 150,
                name: 'Niue',
                abbreviation: 'NU',
                phonePrefix: '683',
                riskGroupID: 0,
            },
            {
                countryId: 151,
                name: 'Norfolk Island',
                abbreviation: 'NF',
                phonePrefix: '672',
                riskGroupID: 0,
            },
            {
                countryId: 152,
                name: 'North Korea',
                abbreviation: 'KP',
                phonePrefix: '850',
                riskGroupID: 1,
            },
            {
                countryId: 153,
                name: 'Northern Marianas',
                abbreviation: 'MP',
                phonePrefix: '1',
                riskGroupID: 1,
            },
            {
                countryId: 154,
                name: 'Norway',
                abbreviation: 'NO',
                phonePrefix: '47',
                riskGroupID: 0,
            },
            {
                countryId: 155,
                name: 'Oman',
                abbreviation: 'OM',
                phonePrefix: '968',
                riskGroupID: 0,
            },
            {
                countryId: 156,
                name: 'Pakistan',
                abbreviation: 'PK',
                phonePrefix: '92',
                riskGroupID: 3,
            },
            {
                countryId: 157,
                name: 'Palau',
                abbreviation: 'PW',
                phonePrefix: '680',
                riskGroupID: 1,
            },
            {
                countryId: 158,
                name: 'Panama',
                abbreviation: 'PA',
                phonePrefix: '507',
                riskGroupID: 0,
            },
            {
                countryId: 159,
                name: 'Papua New Guinea',
                abbreviation: 'PG',
                phonePrefix: '675',
                riskGroupID: 0,
            },
            {
                countryId: 160,
                name: 'Paraguay',
                abbreviation: 'PY',
                phonePrefix: '595',
                riskGroupID: 0,
            },
            {
                countryId: 161,
                name: 'Peru',
                abbreviation: 'PE',
                phonePrefix: '51',
                riskGroupID: 0,
            },
            {
                countryId: 162,
                name: 'Philippines',
                abbreviation: 'PH',
                phonePrefix: '63',
                riskGroupID: 0,
            },
            {
                countryId: 163,
                name: 'Pitcairn Islands',
                abbreviation: 'PN',
                phonePrefix: '870',
                riskGroupID: 0,
            },
            {
                countryId: 164,
                name: 'Poland',
                abbreviation: 'PL',
                phonePrefix: '48',
                riskGroupID: 0,
            },
            {
                countryId: 165,
                name: 'Portugal',
                abbreviation: 'PT',
                phonePrefix: '351',
                riskGroupID: 0,
            },
            {
                countryId: 166,
                name: 'Puerto Rico',
                abbreviation: 'PR',
                phonePrefix: '1',
                riskGroupID: 1,
            },
            {
                countryId: 167,
                name: 'Qatar',
                abbreviation: 'QA',
                phonePrefix: '974',
                riskGroupID: 0,
            },
            {
                countryId: 168,
                name: 'Romania',
                abbreviation: 'RO',
                phonePrefix: '40',
                riskGroupID: 0,
            },
            {
                countryId: 169,
                name: 'Russia',
                abbreviation: 'RU',
                phonePrefix: '7',
                riskGroupID: 0,
            },
            {
                countryId: 170,
                name: 'Rwanda',
                abbreviation: 'RW',
                phonePrefix: '250',
                riskGroupID: 0,
            },
            {
                countryId: 171,
                name: 'Saint Vincent',
                abbreviation: 'VC',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 172,
                name: 'Saint Helena',
                abbreviation: 'SH',
                phonePrefix: '290',
                riskGroupID: 0,
            },
            {
                countryId: 173,
                name: 'Saint Kitts and Nevis',
                abbreviation: 'KN',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 174,
                name: 'Saint Lucia',
                abbreviation: 'LC',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 175,
                name: 'South Georgia',
                abbreviation: 'GS',
                phonePrefix: '508',
                riskGroupID: 0,
            },
            {
                countryId: 176,
                name: 'Turks and Caicos Islands',
                abbreviation: 'TC',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 177,
                name: 'Samoa',
                abbreviation: 'WS',
                phonePrefix: '685',
                riskGroupID: 1,
            },
            {
                countryId: 178,
                name: 'San Marino',
                abbreviation: 'SM',
                phonePrefix: '378',
                riskGroupID: 0,
            },
            {
                countryId: 179,
                name: 'Saudi Arabia',
                abbreviation: 'SA',
                phonePrefix: '966',
                riskGroupID: 0,
            },
            {
                countryId: 180,
                name: 'Senegal',
                abbreviation: 'SN',
                phonePrefix: '221',
                riskGroupID: 0,
            },
            {
                countryId: 181,
                name: 'Seychelles',
                abbreviation: 'SC',
                phonePrefix: '248',
                riskGroupID: 0,
            },
            {
                countryId: 182,
                name: 'Sierra Leone',
                abbreviation: 'SL',
                phonePrefix: '232',
                riskGroupID: 0,
            },
            {
                countryId: 183,
                name: 'Singapore',
                abbreviation: 'SG',
                phonePrefix: '65',
                riskGroupID: 0,
            },
            {
                countryId: 184,
                name: 'Slovakia',
                abbreviation: 'SK',
                phonePrefix: '421',
                riskGroupID: 0,
            },
            {
                countryId: 185,
                name: 'Slovenia',
                abbreviation: 'SI',
                phonePrefix: '386',
                riskGroupID: 0,
            },
            {
                countryId: 186,
                name: 'Solomon Islands',
                abbreviation: 'SB',
                phonePrefix: '677',
                riskGroupID: 0,
            },
            {
                countryId: 187,
                name: 'Somalia',
                abbreviation: 'SO',
                phonePrefix: '252',
                riskGroupID: 0,
            },
            {
                countryId: 188,
                name: 'South Africa',
                abbreviation: 'ZA',
                phonePrefix: '27',
                riskGroupID: 0,
            },
            {
                countryId: 189,
                name: 'US Minor Islands',
                abbreviation: 'UM',
                phonePrefix: '1',
                riskGroupID: 1,
            },
            {
                countryId: 190,
                name: 'South Korea',
                abbreviation: 'KR',
                phonePrefix: '82',
                riskGroupID: 0,
            },
            {
                countryId: 191,
                name: 'Spain',
                abbreviation: 'ES',
                phonePrefix: '34',
                riskGroupID: 0,
            },
            {
                countryId: 192,
                name: 'Sri Lanka',
                abbreviation: 'LK',
                phonePrefix: '94',
                riskGroupID: 3,
            },
            {
                countryId: 193,
                name: 'Sudan',
                abbreviation: 'SD',
                phonePrefix: '249',
                riskGroupID: 1,
            },
            {
                countryId: 194,
                name: 'Suriname',
                abbreviation: 'SR',
                phonePrefix: '597',
                riskGroupID: 0,
            },
            {
                countryId: 195,
                name: 'Swaziland',
                abbreviation: 'SZ',
                phonePrefix: '268',
                riskGroupID: 0,
            },
            {
                countryId: 196,
                name: 'Sweden',
                abbreviation: 'SE',
                phonePrefix: '46',
                riskGroupID: 0,
            },
            {
                countryId: 197,
                name: 'Switzerland',
                abbreviation: 'CH',
                phonePrefix: '41',
                riskGroupID: 0,
            },
            {
                countryId: 198,
                name: 'Syria',
                abbreviation: 'SY',
                phonePrefix: '963',
                riskGroupID: 1,
            },
            {
                countryId: 199,
                name: 'Taiwan',
                abbreviation: 'TW',
                phonePrefix: '886',
                riskGroupID: 0,
            },
            {
                countryId: 200,
                name: 'Tajikistan',
                abbreviation: 'TJ',
                phonePrefix: '992',
                riskGroupID: 0,
            },
            {
                countryId: 201,
                name: 'Tanzania',
                abbreviation: 'TZ',
                phonePrefix: '255',
                riskGroupID: 0,
            },
            {
                countryId: 202,
                name: 'Thailand',
                abbreviation: 'TH',
                phonePrefix: '66',
                riskGroupID: 0,
            },
            {
                countryId: 203,
                name: 'Bahamas',
                abbreviation: 'BS',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 204,
                name: 'Gambia',
                abbreviation: 'GM',
                phonePrefix: '220',
                riskGroupID: 0,
            },
            {
                countryId: 205,
                name: 'Togo',
                abbreviation: 'TG',
                phonePrefix: '228',
                riskGroupID: 0,
            },
            {
                countryId: 206,
                name: 'Tokelau',
                abbreviation: 'TK',
                phonePrefix: '690',
                riskGroupID: 0,
            },
            {
                countryId: 207,
                name: 'Tonga',
                abbreviation: 'TO',
                phonePrefix: '676',
                riskGroupID: 0,
            },
            {
                countryId: 208,
                name: 'Trinidad and Tobago',
                abbreviation: 'TT',
                phonePrefix: '1',
                riskGroupID: 3,
            },
            {
                countryId: 209,
                name: 'Tunisia',
                abbreviation: 'TN',
                phonePrefix: '216',
                riskGroupID: 3,
            },
            {
                countryId: 210,
                name: 'Turkey',
                abbreviation: 'TR',
                phonePrefix: '90',
                riskGroupID: 2,
            },
            {
                countryId: 211,
                name: 'Turkmenistan',
                abbreviation: 'TM',
                phonePrefix: '993',
                riskGroupID: 0,
            },
            {
                countryId: 212,
                name: 'Heard Island',
                abbreviation: 'HM',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 213,
                name: 'Tuvalu',
                abbreviation: 'TV',
                phonePrefix: '688',
                riskGroupID: 0,
            },
            {
                countryId: 214,
                name: 'US Virgin Islands',
                abbreviation: 'VI',
                phonePrefix: '1',
                riskGroupID: 1,
            },
            {
                countryId: 215,
                name: 'Uganda',
                abbreviation: 'UG',
                phonePrefix: '256',
                riskGroupID: 3,
            },
            {
                countryId: 216,
                name: 'Ukraine',
                abbreviation: 'UA',
                phonePrefix: '380',
                riskGroupID: 0,
            },
            {
                countryId: 217,
                name: 'United Arab Emirates',
                abbreviation: 'AE',
                phonePrefix: '971',
                riskGroupID: 0,
            },
            {
                countryId: 218,
                name: 'United Kingdom',
                abbreviation: 'GB',
                phonePrefix: '44',
                riskGroupID: 0,
            },
            {
                countryId: 219,
                name: 'United States',
                abbreviation: 'US',
                phonePrefix: '1',
                riskGroupID: 0,
            },
            {
                countryId: 220,
                name: 'Saint Pierre',
                abbreviation: 'PM',
                phonePrefix: '508',
                riskGroupID: 0,
            },
            {
                countryId: 221,
                name: 'Uruguay',
                abbreviation: 'UY',
                phonePrefix: '598',
                riskGroupID: 0,
            },
            {
                countryId: 222,
                name: 'Uzbekistan',
                abbreviation: 'UZ',
                phonePrefix: '998',
                riskGroupID: 0,
            },
            {
                countryId: 223,
                name: 'Vanuatu',
                abbreviation: 'VU',
                phonePrefix: '678',
                riskGroupID: 3,
            },
            {
                countryId: 224,
                name: 'Vatican City',
                abbreviation: 'VA',
                phonePrefix: '379',
                riskGroupID: 0,
            },
            {
                countryId: 225,
                name: 'Venezuela',
                abbreviation: 'VE',
                phonePrefix: '58',
                riskGroupID: 0,
            },
            {
                countryId: 226,
                name: 'Vietnam',
                abbreviation: 'VN',
                phonePrefix: '84',
                riskGroupID: 0,
            },
            {
                countryId: 227,
                name: 'Wallis and Futuna',
                abbreviation: 'WF',
                phonePrefix: '681',
                riskGroupID: 0,
            },
            {
                countryId: 228,
                name: 'Islands',
                abbreviation: 'EH',
                phonePrefix: '212',
                riskGroupID: 0,
            },
            {
                countryId: 229,
                name: 'Yemen',
                abbreviation: 'YE',
                phonePrefix: '967',
                riskGroupID: 3,
            },
            {
                countryId: 230,
                name: 'Zambia',
                abbreviation: 'ZM',
                phonePrefix: '260',
                riskGroupID: 0,
            },
            {
                countryId: 231,
                name: 'Zimbabwe',
                abbreviation: 'ZW',
                phonePrefix: '263',
                riskGroupID: 0,
            },
            {
                countryId: 232,
                name: 'Sao Tome',
                abbreviation: 'ST',
                phonePrefix: '239',
                riskGroupID: 0,
            },
            {
                countryId: 233,
                name: 'Jersey Island',
                abbreviation: 'JE',
                phonePrefix: '44',
                riskGroupID: 0,
            },
            {
                countryId: 234,
                name: 'Nepal',
                abbreviation: 'NP',
                phonePrefix: '977',
                riskGroupID: 0,
            },
            {
                countryId: 235,
                name: 'Palestinian Territory',
                abbreviation: 'PS',
                phonePrefix: '970',
                riskGroupID: 0,
            },
            {
                countryId: 236,
                name: 'Reunion Island',
                abbreviation: 'RE',
                phonePrefix: '262',
                riskGroupID: 0,
            },
            {
                countryId: 237,
                name: 'Serbia',
                abbreviation: 'RS',
                phonePrefix: '381',
                riskGroupID: 2,
            },
            {
                countryId: 238,
                name: 'Montenegro',
                abbreviation: 'ME',
                phonePrefix: '382',
                riskGroupID: 0,
            },
            {
                countryId: 239,
                name: 'Antarctica',
                abbreviation: 'AQ',
                phonePrefix: '672',
                riskGroupID: 0,
            },
            {
                countryId: 240,
                name: 'Svalbard and Jan Mayen',
                abbreviation: 'SJ',
                phonePrefix: '47',
                riskGroupID: 0,
            },
            {
                countryId: 241,
                name: 'French Guiana',
                abbreviation: 'GF',
                phonePrefix: '594',
                riskGroupID: 0,
            },
            {
                countryId: 242,
                name: 'Guernsey',
                abbreviation: 'GG',
                phonePrefix: '44',
                riskGroupID: 0,
            },
            {
                countryId: 243,
                name: 'Aland Islands',
                abbreviation: 'AX',
                phonePrefix: '672',
                riskGroupID: 0,
            },
            {
                countryId: 244,
                name: 'Isle Of Man',
                abbreviation: 'IM',
                phonePrefix: '44',
                riskGroupID: 0,
            },
            {
                countryId: 245,
                name: 'Bonaire',
                abbreviation: 'BQ',
                phonePrefix: '599',
                riskGroupID: 0,
            },
            {
                countryId: 246,
                name: 'Curacao',
                abbreviation: 'CW',
                phonePrefix: '599',
                riskGroupID: 0,
            },
            {
                countryId: 247,
                name: 'Saint Barthelemy',
                abbreviation: 'BL',
                phonePrefix: '590',
                riskGroupID: 0,
            },
            {
                countryId: 248,
                name: 'Saint Martin',
                abbreviation: 'MF',
                phonePrefix: '599',
                riskGroupID: 0,
            },
            {
                countryId: 249,
                name: 'Sint Maarten (Dutch Part)',
                abbreviation: 'SX',
                phonePrefix: '590',
                riskGroupID: 0,
            },
            {
                countryId: 250,
                name: 'eToro',
                abbreviation: 'ZZ',
                phonePrefix: '972',
                riskGroupID: 0,
            }
        );
        return countriesList;
    }
}

export default new ResolveCountryService();
