const prefix = '';

export default {
    home: `${prefix}/home`,
    loading: `${prefix}/loading`,
    login: `${prefix}/login`,
    loginIB: `${prefix}/loginIB`,
    autoLogin: `${prefix}/autoLogin`,
    registration: `${prefix}/registration`,
    registrationInvesting: `${prefix}/registrationInvesting`,
    forgotPassword: `${prefix}/forgotPassword`,
    resetPassword: `${prefix}/resetPassword/:token/:ref`,
    confirmation: `${prefix}/confirmation/:id/:ref`,
    notFound: `${prefix}/not-found`,
    watchlistItem: (id: number) => `${prefix}/dashboard/${id}`,
    editFavoritesList: `${prefix}/watchList/:id/edit`,
    openTrades: `${prefix}/openTrades`,
    investors: `${prefix}/investors`,
    investments: `${prefix}/investments/:id`,
    favorites: `${prefix}/favorites`,
    followed: `${prefix}/followed`,
    desktop: `${prefix}/desktop`,
    editOpenPosition: `${prefix}/editOpenPosition/:id`,
    editPendingOrder: `${prefix}/editPendingOrder/:id`,
    buySellPosition: `${prefix}/buySellPosition/:symbol`,
    marketOrder: `${prefix}/marketOrder/:symbol/:side`,
    allOrders: `${prefix}/allOrders`,
    result: `${prefix}/result`,
    cash: `${prefix}/cash`,
    deals: `${prefix}/deals`,
    news: `${prefix}/news`,
    uploadDocuments: `${prefix}/uploadDocuments`,
    notifications: `${prefix}/notifications`,
    more: `${prefix}/more`,
    addFunds: `${prefix}/addFunds`,
    addFundsCategory: `${prefix}/addFundsCategory/:amount`,
    addFundsCard: `${prefix}/addFundsCard/:provider/:method/:amount`,
    addFundsAlternativePayment: `${prefix}/addFundsAlternativePayment/:provider/:method/:amount`,
    addFundsAlternative: `${prefix}/addFundsAlternative/:amount`,
    addFundsBank: `${prefix}/addFundsBank/:amount`,
    commissions: `${prefix}/commissions`,
    chartingLibrary: `${prefix}/chart/:symbol`,
    interestSwap: `${prefix}/interestSwap`,
    withdrawFunds: `${prefix}/withdrawFunds`,
    settings: `${prefix}/settings`,
    accounts: `${prefix}/accounts`,
    verificationCenter: `${prefix}/verificationCenter`,
    changePassword: `${prefix}/changePassword`,
    notificationsSettings: `${prefix}/notificationsSettings`,
    clientReport: `${prefix}/clientReport`,
    resultReport: `${prefix}/resultReport`,
    swapReport: `${prefix}/swapReport`,
};
