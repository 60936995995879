import { useEffect, useState } from 'react';
import CustomerInfoService from '~/api-services/CustomerInfoService';
import { CustomerInfo } from '~/api-services/CustomerInfoService/types';

export const useCustomerInfo = (provider: string, method: string) => {
    const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        if(provider === 'BESTEXCH') {
            setCustomerInfo(undefined);
            
        }
        else {
            const { status, data } =  provider === 'ARENAMONEY' ? await CustomerInfoService.getCustomerInfoArenaMoneyPayForm() : provider === 'PAYTIKO' ? await CustomerInfoService.getCustomerInfoPaytikoPayForm() : provider === 'TRUSTPAYMENTS' && method === 'IDEAL' ? await CustomerInfoService.getCustomerInfoIdeal() : await CustomerInfoService.getCustomerInfo(provider);
            if (status === 200 && data.status) {
                setCustomerInfo(data.returnData);
            }
        }
    }

    return {
        customerInfo,
    };
};
