import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Linking, Pressable } from 'react-native';

import { Button, Text } from '~/components/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppSelector, useStyles } from '~/hooks';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import config from '~/config';
import { scaleSize, isDesktop } from '~/constants/scale';
import { Theme } from '~/theme';
import { FONT_SIZE_S } from '~/constants';
import { Ionicons, FontAwesome, Entypo, AntDesign, Octicons } from '@expo/vector-icons';
import Header from './Header';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { dealerSelector, traderIdSelector, userAuthDataSelector } from '~/state/selectors';
import { AuthDataService } from '~/api-services';
import { MultiaccountInfoReturnData } from '~/api-services/AuthDataService/types';

type Props = {
    setActiveRightTab?: Function;
};

export default function More({ setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    const dealer = useAppSelector(dealerSelector);
    const traderId = useAppSelector(traderIdSelector);
    const { type } = useAppSelector(userAuthDataSelector);
    const [multiaccountInfo, setMultiaccountInfo] = useState<MultiaccountInfoReturnData>(null);

    useEffect(() => {
        (async () => {
            const { status, data } = await AuthDataService.getMultiaccountInfo();
            if (status === 200 && data.status) {
                setMultiaccountInfo(data.returnData);
            }
        })();
    }, []);


    return (
        <>
            {!isDesktop() ? <Header /> : null}
            <ScrollbarWrapper>
                <View style={styles.container}>
                    <View style={styles.viewContainer}>
                        <View style={styles.linksContainer}>
                            <Pressable
                                style={styles.linkContainer}
                                onPress={() => {
                                    isDesktop() && setActiveRightTab
                                        ? setActiveRightTab('settings')
                                        : linkTo(paths.settings);
                                }}
                            >
                                <View style={styles.iconCircle}>
                                    <Ionicons name="settings-sharp" size={scaleSize(12)} style={styles.iconColor} />
                                </View>
                                <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                    {'settings-label'}
                                </Text>
                            </Pressable>
                            {multiaccountInfo && multiaccountInfo.enableMultiaccount ?
                                <Pressable
                                    style={styles.linkContainer}
                                    onPress={() => {
                                        isDesktop() && setActiveRightTab
                                            ? setActiveRightTab('accounts')
                                            : linkTo(paths.accounts);
                                    }}
                                >
                                    <View style={styles.iconCircle}>
                                        <Ionicons name="person-circle-outline" size={scaleSize(12)} style={styles.iconColor} />
                                    </View>
                                    <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                        {'accounts-label'}
                                    </Text>
                                </Pressable> : null}
                            <Pressable
                                style={styles.linkContainer}
                                onPress={() => Linking.openURL(`${config.CONTACT_US_URL}`)}
                            >
                                <View style={styles.iconCircle}>
                                    <FontAwesome name="phone" size={scaleSize(12)} style={styles.iconColor} />
                                </View>
                                <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                    {'contact-us'}
                                </Text>
                            </Pressable>
                            {type === 'REAL' ?
                                <>
                                    <Pressable
                                        style={styles.linkContainer}
                                        onPress={() =>
                                            isDesktop() && setActiveRightTab
                                                ? setActiveRightTab('withdraw')
                                                : linkTo(paths.withdrawFunds)
                                        }
                                    >
                                        <View style={styles.iconCircle}>
                                            <Ionicons name="wallet-outline" size={scaleSize(12)} style={styles.iconColor} />
                                        </View>
                                        <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                            {'withdraw-funds'}
                                        </Text>
                                    </Pressable>
                                    <Pressable
                                        style={styles.linkContainer}
                                        onPress={() =>
                                            isDesktop() && setActiveRightTab
                                                ? setActiveRightTab('upload-documents')
                                                : linkTo(paths.uploadDocuments)
                                        }
                                    >
                                        <View style={styles.iconCircle}>
                                            <Entypo name="upload-to-cloud" size={scaleSize(12)} style={styles.iconColor} />
                                        </View>
                                        <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                            {'upload-documents'}
                                        </Text>
                                    </Pressable>
                                    <Pressable
                                        style={styles.linkContainer}
                                        onPress={() =>
                                            isDesktop() && setActiveRightTab
                                                ? setActiveRightTab('veryfication-center')
                                                : linkTo(paths.verificationCenter)
                                        }
                                    >
                                        <View style={styles.iconCircle}>
                                            <Octicons name="check" size={scaleSize(12)} style={styles.iconColor} />
                                        </View>
                                        <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                            {'verification-center'}
                                        </Text>
                                    </Pressable>
                                    {dealer === 'EVER' || (traderId === 326836 || traderId === 325111) ?
                                        <Pressable
                                            style={styles.linkContainer}
                                            onPress={() =>
                                                isDesktop() && setActiveRightTab
                                                    ? setActiveRightTab('client-report')
                                                    : linkTo(paths.clientReport)
                                            }
                                        >
                                            <View style={styles.iconCircle}>
                                                <AntDesign name="profile" size={scaleSize(12)} style={styles.iconColor} />
                                            </View>
                                            <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                                {'client-report'}
                                            </Text>
                                        </Pressable> : null}
                                    {dealer === 'EVER' ?
                                        <Pressable
                                            style={styles.linkContainer}
                                            onPress={() =>
                                                isDesktop() && setActiveRightTab
                                                    ? setActiveRightTab('result-report')
                                                    : linkTo(paths.resultReport)
                                            }
                                        >
                                            <View style={styles.iconCircle}>
                                                <AntDesign name="profile" size={scaleSize(12)} style={styles.iconColor} />
                                            </View>
                                            <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                                {'result-report'}
                                            </Text>
                                        </Pressable> : null}
                                    {dealer === 'EVER' ?
                                        <Pressable
                                            style={styles.linkContainer}
                                            onPress={() =>
                                                isDesktop() && setActiveRightTab
                                                    ? setActiveRightTab('swap-report')
                                                    : linkTo(paths.swapReport)
                                            }
                                        >
                                            <View style={styles.iconCircle}>
                                                <AntDesign name="profile" size={scaleSize(12)} style={styles.iconColor} />
                                            </View>
                                            <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                                {'swap-report'}
                                            </Text>
                                        </Pressable> : null}
                                    <Pressable
                                        style={styles.linkContainer}
                                        onPress={() => Linking.openURL(dealer === 'EVER' ? `${config.LEGAL_DOCUMENTS_EVER_URL}` : dealer == 'MAURITIUS' ? config.LEGAL_DOCUMENTS_MAURITIUS_URL : `${config.LEGAL_DOCUMENTS_URL}`)}
                                    >
                                        <View style={styles.iconCircle}>
                                            <Ionicons
                                                name="document-text-outline"
                                                size={scaleSize(12)}
                                                style={styles.iconColor}
                                            />
                                        </View>
                                        <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                            {'legal-documents'}
                                        </Text>
                                    </Pressable>
                                    <Pressable
                                        style={styles.linkContainer}
                                        onPress={() => Linking.openURL(dealer === 'EVER' ? `${config.PRIVATE_POLICY_EVER_URL}` : dealer == 'MAURITIUS' ? config.PRIVATE_POLICY_MAURITIUS_URL : `${config.PRIVATE_POLICY_URL}`)}
                                    >
                                        <View style={styles.iconCircle}>
                                            <Ionicons
                                                name="shield-checkmark-sharp"
                                                size={scaleSize(12)}
                                                style={styles.iconColor}
                                            />
                                        </View>
                                        <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                            {'privacy-policy'}
                                        </Text>
                                    </Pressable></> : null}
                        </View>
                        {type === 'REAL' ?
                            <View style={styles.addFundsContainer}>
                                <Button
                                    title="account-balance-add-funds"
                                    onPress={() => {
                                        if (isDesktop()) {
                                            setActiveRightTab ? setActiveRightTab('add-money') : null;
                                        } else {
                                            linkTo(paths.addFunds);
                                        }
                                    }}
                                />
                            </View> : null}
                    </View>
                </View>
            </ScrollbarWrapper>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        iconCircle: {
            marginRight: scaleSize(10),
            height: scaleSize(22),
            width: scaleSize(22),
            borderRadius: scaleSize(11),
            borderWidth: 1,
            borderColor: 'gray',
            justifyContent: 'center',
            alignItems: 'center',
        },
        linkText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        iconColor: { color: theme.tabIconSelected },
        viewContainer: { height: '90%', justifyContent: 'space-between' },
        linksContainer: { justifyContent: 'space-between' },
        linkContainer: {
            paddingVertical: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            flexDirection: 'row',
            alignItems: 'center',
        },
        addFundsContainer: { paddingBottom: scaleSize(20) },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
    });
