import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import SwapReport from '../features/SwapReport';

export default function SwapReportScreen() {
    return (
        <ScreenWrapper>
            <SwapReport/>
        </ScreenWrapper>
    );
}
