import React, { useState } from 'react';
import { StyleSheet, Platform, View, Linking } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import FromToSelector from '../Result/FromToSelector';
import { Button } from '~/components/core';
import config from '~/config';
import { appSettingsSelector, userAuthDataSelector } from '~/state/selectors';

const now = new Date();
const startPeriod = new Date(now.getTime() - 1 * 24 * 3600 * 1000);
startPeriod.setHours(0);
startPeriod.setMinutes(0);
startPeriod.setSeconds(0);
startPeriod.setMilliseconds(0);

export default function ResultReport() {
    const [from, setFrom] = useState(startPeriod);
    const [to, setTo] = useState(now);
    const styles = useStyles(stylesGenerator);
    const { sessionId } = useAppSelector(userAuthDataSelector);
    const { language } = useAppSelector(appSettingsSelector);



    function downloadReport() {
        
        const startPeriod = new Date(from.getTime());
        startPeriod.setHours(0);
        startPeriod.setMinutes(0);
        startPeriod.setSeconds(0);
        startPeriod.setMilliseconds(0);

        var endPeriod = new Date(to.getTime());
        endPeriod.setHours(0);
        endPeriod.setMinutes(0);
        endPeriod.setSeconds(0);
        endPeriod.setMilliseconds(0);
        if(endPeriod.getTime() - startPeriod.getTime()>365 * 24 * 60 * 60 * 1000) {
            endPeriod = new Date(startPeriod.getTime() + 365 * 24 * 60 * 60 * 1000);
        }
        setTo(endPeriod);

        var url = config.PLATFORM_BASE_URL + 'reports/resultReport?sessionId=' + sessionId + '&from=' + startPeriod.getTime() + '&to=' + endPeriod.getTime() + '&language=' + language;

        Linking.openURL(url);

    }

    return (
        <>
            <View style={styles.ordersContainer}>
                <Button title="Download PDF" onPress={() => downloadReport()} disabled={from === null} />
            </View>
            <FromToSelector setFromButton={setFrom} setToButton={setTo} prevPeriod={startPeriod} hideTo={false} />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
    });
