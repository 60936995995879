import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { Platform } from 'react-native';

import config from '~/config';

import { LoginResponse, LoginResponseReturnData } from './AuthDataService/types';

const apiClient = axios.create({
    baseURL: `${config.API_BASE_URL}`,
    headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
    },
});

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        // TODO: log for test env only
        console.log('API error:', error.message);
        return Promise.reject(error);
    }
);

let authInterceptorIndex: number;

export const setupSessionRefetchInterceptor = async (
    token: string,
    onComplete: (authData: LoginResponseReturnData) => void,
    onError: () => void
) => {
    authInterceptorIndex = createAuthRefreshInterceptor(apiClient, async (failedRequest) => {
        try {
            const response = await apiClient.post<LoginResponse>('/api/24yiedlogin/loginWithToken', {
                token,
                apiVersion: config.API_VERSION,
                website: config.WEBSITE,
                source: Platform.OS.toUpperCase(),
                sourceVersion: config.SOURCE_VERSION,
                sourceType: Platform.OS.toUpperCase(),
            });

            if (response.status !== 200 || !response.data.returnData) {
                onError();
                return Promise.reject('Failed to refetch session.');
            }

            const authData = response.data.returnData;
            authData.token = token;

            setupAuthHeaders(authData.sessionId, authData.language);
            failedRequest.response.config.headers.s = authData.sessionId;
            onComplete(authData);
        } catch (e) {
            onError();
            return Promise.reject('Failed to refetch session.');
        }
        return Promise.resolve();
    });
};

export const ejectSessionRefetchInterceptor = () => {
    if (authInterceptorIndex !== undefined) {
        apiClient.interceptors.response.eject(authInterceptorIndex);
    }
};

export const setupAuthHeaders = (sessionId: string, language: string) => {
    apiClient.defaults.headers.common.s = sessionId;
    apiClient.defaults.headers.common.l = language;
};

export const clearAuthHeaders = () => {
    delete apiClient.defaults.headers.common.s;
    delete apiClient.defaults.headers.common.l;
};

export default apiClient;
