import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import ResultReport from '../features/ResultReport';

export default function ResultReportScreen() {
    return (
        <ScreenWrapper>
            <ResultReport/>
        </ScreenWrapper>
    );
}
