import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Platform, View, Linking } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import FromToSelector from '../Result/FromToSelector';
import { Button } from '~/components/core';
import { appSettingsSelector, userAuthDataSelector } from '~/state/selectors';
import ReportsService from '~/api-services/ReportsService';
import config from '~/config';
import Loader from '~/components/core/Loader';

const now = new Date();
const startPeriod = new Date(now.getTime() - 1 * 24 * 3600 * 1000);
startPeriod.setHours(0);
startPeriod.setMinutes(0);
startPeriod.setSeconds(0);
startPeriod.setMilliseconds(0);

export default function SwapReport() {
    const [from, setFrom] = useState(startPeriod);
    const [to, setTo] = useState(now);
    const styles = useStyles(stylesGenerator);
    const { sessionId } = useAppSelector(userAuthDataSelector);
    const { language } = useAppSelector(appSettingsSelector);
    const swapReportPollRef = useRef<NodeJS.Timer>();
    const [swapReportId, setSwapReportId] = useState('');
    const [pdfStatus, setPdfStatus] = useState('GENERATING');
    const [loading, setLoading] = useState(false);

    async function generateReport() {
        setLoading(true);
        var startPeriod = new Date(from.getTime());
        startPeriod.setHours(0);
        startPeriod.setMinutes(0);
        startPeriod.setSeconds(0);
        startPeriod.setMilliseconds(0);

        var endPeriod = new Date(to.getTime());
        endPeriod.setHours(0);
        endPeriod.setMinutes(0);
        endPeriod.setSeconds(0);
        endPeriod.setMilliseconds(0);
        if(endPeriod.getTime() - startPeriod.getTime()>365 * 24 * 60 * 60 * 1000) {
            endPeriod = new Date(startPeriod.getTime() + 365 * 24 * 60 * 60 * 1000);
        }
        setTo(endPeriod);
        
        const { status, data } = await ReportsService.generateSwapReport(sessionId, startPeriod.getTime(), endPeriod.getTime(), language);
        if (status === 200 && data.status) {
            setSwapReportId(data.returnData.id);
        }

    }

    async function checkReportStatus() {
        if(swapReportId!='') {
            const { status, data } = await ReportsService.reportStatus(swapReportId);
            if (status === 200 && data.status) {
                if(data.returnData.status == 'DONE') {
                    setLoading(false);
                    setPdfStatus('DONE');
                }
             }
        }
    }

    function downloadPDF() {
        var url = config.PLATFORM_BASE_URL + 'reports/reportDownload/' + swapReportId;
        Linking.openURL(url);
        clearInterval(swapReportPollRef.current);
        setSwapReportId('');
        setPdfStatus('GENERATING');
    }

    useEffect(() => {
        if (swapReportId) {
            swapReportPollRef.current = setInterval(checkReportStatus, 5000);
        }
        return () => {
            swapReportPollRef.current && clearInterval(swapReportPollRef.current);
        };
    }, [swapReportId]);

    return (
        <>
            
            <View style={styles.ordersContainer}>
                <Loader loading={loading} />
                <Button title={(pdfStatus!='DONE' && swapReportId=='') ? "Generate PDF" : pdfStatus=='DONE' ? 'Document is ready. Click to Download' : 'Generating... Please wait!'} onPress={() => pdfStatus!='DONE' || swapReportId=='' ? generateReport() : downloadPDF()} disabled={from === null} />
            </View>
            <FromToSelector setFromButton={setFrom} setToButton={setTo} prevPeriod={startPeriod} hideTo={false} />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
    });
