import { useEffect, useState, useRef } from 'react';
import { CoreDataService } from '~/api-services';
import { Message } from '~/api-services/CoreDataService/types';
import { userIsLoggedInSelector, accountPendingMessagesSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';
import { useIsAppActive } from './useIsAppActive';
import { changeNotificationsActive, loadUserKeepALiveData } from "~/state/reducers/userDataSlice";
import { useAppDispatch } from "~/hooks/useAppDispatch";

export const useNotifications = () => {
    const pendingMessages = useAppSelector(accountPendingMessagesSelector);
    const [messageArray, setMessageArray] = useState<Message[]>([]);
    const reloadDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);
    const [lastId, setLastId] = useState<string>('');
    const [modified, setModified] = useState<number>(0);
    const [notificationsCount, setNotificationsCount] = useState<number>(0);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(changeNotificationsActive(true));
        dispatch(loadUserKeepALiveData());
        return () => {
            dispatch(changeNotificationsActive(false));
        };
    }, []);

    useEffect(() => {
        addPendingMessages();
    }, [pendingMessages, messageArray]);

    useEffect(() => {
        if (isAppActive && isLoggedIn) {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
            reloadDataPollRef.current = setInterval(() => {
                loadData();
            }, 60000); // refetch 60 seconds
            if (lastId == '') {
                // only call when last id is empty
                loadData();
            }
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
        return () => {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        };
    }, [isLoggedIn, isAppActive, lastId, modified, notificationsCount, unreadCount]);

    async function addPendingMessages() {
        const newOrUpdate = pendingMessages || [];
        if (newOrUpdate.length === 0) {
            return;
        }

        // convert existing notification array to map
        let notificationMap = messageArray.reduce<Map<string, Message>>(function(map, obj) {
            map.set(obj.id, obj);
            return map;
        }, new Map<string, Message>());

        // convert added or updated notification array to map
        let changed = false;
        newOrUpdate.forEach(function(n: Message) {
            if (!notificationMap.has(n.id)) {
                let clone = { ...n };
                notificationMap.set(clone.id, clone);
                changed = true;
            }
        })
        if (!changed) {
            return;
        }

        // collect only values, which have a key in keepIdsMap
        let list : Message[] = [];
        for (let [_, v] of notificationMap) {
            list.push(v);
        }

        // sort array newest to oldest
        list.sort(function(a,b) {
            if (a.timestamp > b.timestamp) {
                return -1;
            }
            if (b.timestamp > a.timestamp) {
                return 1;
            }
            return 0;
        });

        changeList(list);
    }

    async function loadData() {
        if (lastId) {
            const { status, data } = await CoreDataService.reloadAllMessages({ id: lastId, count: notificationsCount, modified: modified, unreadCount: unreadCount });
            if (status === 200 && data.status) {
                if (data.returnData.action == 'none') {
                    // no change - do nothing
                } else if (data.returnData.action == 'replace') {
                    changeList(data.returnData.list);
                } else if (data.returnData.action == 'merge') {
                    // convert existing notification array to map
                    let notificationMap = messageArray.reduce<Map<string, Message>>(function(map, obj) {
                        map.set(obj.id, obj);
                        return map;
                    }, new Map<string, Message>());

                    // convert added or updated notification array to map
                    const newOrUpdate = data.returnData.list || [];
                    newOrUpdate.forEach(function(n: Message) {
                        notificationMap.set(n.id, n);
                    })

                    // convert keep ids array to map
                    let keepIdsMap = data.returnData.keepIds.reduce<Map<string, boolean>>(function(map, id) {
                        map.set(id, true);
                        return map;
                    }, new Map<string, boolean>());

                    // convert unread ids array to map
                    let unreadIdsMap = data.returnData.unreadIds.reduce<Map<string, boolean>>(function(map, id) {
                        map.set(id, true);
                        return map;
                    }, new Map<string, boolean>());

                    // collect only values, which have a key in keepIdsMap
                    let list : Message[] = [];
                    for (let [k, v] of notificationMap) {
                        if (keepIdsMap.has(k)) {
                            const unread = unreadIdsMap.has(k);
                            let clone = { ...v };
                            clone.read = !unread;
                            list.push(clone);
                        }
                    }

                    // sort array newest to oldest
                    list.sort(function(a,b) {
                        if (a.timestamp > b.timestamp) {
                            return -1;
                        }
                        if (b.timestamp > a.timestamp) {
                            return 1;
                        }
                        return 0;
                    });

                    changeList(list);
                } else {
                    // not supported action - clear cache and force get initial data
                    setLastId('');
                }
            }
        } else {
            const {status, data} = await CoreDataService.getAllMessages();
            if (status === 200 && data.status) {
                changeList(data.returnData);
            }
        }
    }

    function changeList(list: Message[]) {
        setMessageArray(list);
        if (list && list.length > 0) {
            setLastId(list[0].id);
        }
        const newModified = list.reduce((a, n) => Math.max(a, n.modified || 0), 0);
        setModified(newModified);
        setNotificationsCount(list.length);
        let unreadMessageIds: string[] = list.filter((message) => !message.read).map((message) => message.id);
        setUnreadCount(unreadMessageIds.length);

        if (unreadMessageIds.length > 0) {
            CoreDataService.markMessagesAsRead(unreadMessageIds);
        }
    }

    return {
        messageArray,
    };
};
