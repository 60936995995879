import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';

import { CoreDataService, LoadService } from '~/api-services';
import type { UserData, Account, InitialData, Trades } from '~/api-services/LoadService/types';
import type { KeepALive } from '~/api-services/CoreDataService/types';
import { thunkGenericApiReducerBuilder } from './utils';
import type { ThunkAPI } from '../store';

type UserDataType = Pick<UserData, 'traderId' | 'account' | 'accountDetails' | 'pendingMessagesCount' | 'openTradesActive' | 'notificationsActive' >;

export interface UserDataState extends ResponseMetadata {
    data: UserDataType | null;
}

const initialState: UserDataState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    data: null,
};

export const loadUserData = createAsyncThunk<InitialData, void, ThunkAPI>('userData/load', async (_, thunkAPI) => {
    try {
        const { status, data } = await LoadService.getInitialData();
        if (status !== 200 || !data.returnData) {
            throw new Error(data.errorCode || 'Unauthorized');
        }
        return data.returnData;
    } catch (e) {
        return thunkAPI.rejectWithValue('Unauthorized');
    }
});

export const loadUserKeepALiveData = createAsyncThunk<KeepALive, void, ThunkAPI>(
    'userData/loadKeepALive',
    async (_, thunkAPI) => {
        try {
            const otherState = thunkAPI.getState().userData.data;
            const { status, data } = await CoreDataService.getKeepALive(otherState.openTradesActive, otherState.notificationsActive);
            if (status !== 200 || !data.returnData) {
                throw new Error(data.errorCode || 'Unauthorized');
            }
            return data.returnData;
        } catch (e) {
            return thunkAPI.rejectWithValue('Unauthorized');
        }
    }
);

export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        clearState: () => initialState,
        changeOpenTradesActive: (state, action: PayloadAction<boolean>) => {
            state.data.openTradesActive = action.payload;
        },
        changeNotificationsActive: (state, action: PayloadAction<boolean>) => {
            state.data.notificationsActive = action.payload;
        },
    },
    extraReducers: (builder) => {
        thunkGenericApiReducerBuilder(builder, loadUserData, (state: UserDataState, { loadData }: InitialData) => {
            state.data = {
                traderId: loadData.traderId,
                account: loadData.account,
                accountDetails: loadData.accountDetails,
                pendingMessagesCount: loadData.pendingMessagesCount,
                notificationsActive: false,
                openTradesActive: false,
            };
        });
        thunkGenericApiReducerBuilder(builder, loadUserKeepALiveData, (state: UserDataState, payload: KeepALive) => {
            if (state.data) {
                state.data.account.balanceValues = payload.balanceValues;
                state.data.pendingMessagesCount = payload.unreadMessages;
                if (state.data.openTradesActive && payload.returnTrades) {
                    state.data.account.positions = payload.trades;
                }
                if (state.data.notificationsActive && payload.returnPendingMessages) {
                    state.data.account.pendingMessages = payload.pendingMessages;
                }
            }
        });
    },
});

export const { clearState, changeOpenTradesActive, changeNotificationsActive } = userDataSlice.actions;

export default userDataSlice.reducer;
