import axios from '../baseClient';
import { InstrumentNewsResponse, News2Request, NewsResponse, ReloadNews2V2Request, ReloadNews2V2Response } from './types';

class NewsService {
    private static domain = '/api/24yieldnews';

    getAllNews2(allNews2Request: News2Request) {
        return axios.post<NewsResponse>(`${NewsService.domain}/getAllNews2`, allNews2Request);
    }

    reloadAllNews2V2(request: ReloadNews2V2Request) {
        return axios.post<ReloadNews2V2Response>(`${NewsService.domain}/reloadAllNews2V2`, request);
    }

    getInstrumentNews(instrumentCode: string) {
        return axios.post<InstrumentNewsResponse>(`${NewsService.domain}/getInstrumentNews`, {instrumentCode: instrumentCode});
    }
}

export default new NewsService();
